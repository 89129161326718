import React from 'react';
import fetchJsonData from './JsonData';
import { useEffect, useState } from 'react';

const Footer = () => {
  const [data, setData] = useState(null); 

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchJsonData();
      setData(fetchedData);
    };
    loadData();
  }, []);

  if (!data || !data.footerLinks || !data.footerLinks[0] || !data.footerLinks[1]) {
    return null; // Return null if data or links are not available yet
  }

  return (
    <div className="main-body">
      <footer className="footer">
        <div className="footer-img-container">
          <a href={data.customerPageUrl}>
            <img className="footer-img" src={data.mainFooterLogo} alt="" />
          </a>
          
        </div>
        <p>{data.footerCredential}</p>
        <div className="privacy-rights">
         
          {data.footerLinks[0] && (
            <a href={data.footerLinks[0].targetUrl}>
              <span>{data.footerLinks[0].label} |</span>
            </a>
          )}
          {data.footerLinks[1] && (
            <a href={data.footerLinks[1].targetUrl}>
              <span>{data.footerLinks[1].label}</span>
            </a>
          )}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
