const fetchJsonData = async () => {
    // url points to page data
    //To do move this url to static app enviorment variable 
    const pageDataUrl = "https://hs-krafton.azureedge.net/config/india_latest.json";
    // Key name of data which cached in local storage 
    const pageDataKey = "Page_Data";
     // checking to see if page data is already stored on local storage
    const cache = localStorage.getItem(pageDataKey);
    const cacheData = cache ? JSON.parse(cache) : null;
        /* We are storing our configuration in Azure storage,
        Azure storage doesn't support if-modified-since in header 
        of GET request we are using HEAD reqquest instade and checking
        Last-Modified dates manuale*/
        const checkForUpdates = async () => {
            // Added Try and Catch to track steps
            try {
                // Perform HEAD request to check the Last-Modified date
                const headResponse = await fetch(pageDataUrl, { method: "HEAD" });
                if (!headResponse.ok) {
                    throw new Error('HEAD request failed!');
                }
                // retrieving the 'Last-Modified'  header 
                const lastModified = headResponse.headers.get('Last-Modified');

                // Check if the cache exists and if data is valid
                if (cacheData && cacheData.lastModified === lastModified) {
                    // Use cached data if it's not modified
                    return cacheData.data
                } else {
                    // If data is new or not cached clear the old cache(Please read it MATT :( I WRITE IT FOR YOU)
                    if (cacheData) {
                        //  removing old cached data
                        localStorage.removeItem(pageDataKey);
                    }

                    // Fetch new data if cache is outdated or doesn't exist
                    const getResponse = await fetch(pageDataUrl);
                    if (!getResponse.ok) {
                        throw new Error('GET request failed!');
                    }

                    const jsonData = await getResponse.json();

                    // Save new data to localStorage with the Last-Modified value
                    localStorage.setItem(pageDataKey, JSON.stringify({ data: jsonData, lastModified }));
                    return jsonData;
                }
            } catch (error) {
                console.error("Fetch error:", error);
                return null
            }
        };

        const data = await checkForUpdates();

        // Set default styles before updating colors from JSON


        return data

    }

export default  fetchJsonData;




