import React, { useState } from 'react';
import MainGameCard from './MainGameCard';
import '../styles/GameCardGrid.css'; // Import the CSS file

const GamesCardGrid = ({ games = [] }) => {
  // Filter enabled games
  const enabledGames = games.filter(g => g.enabled);
  
  // Sort the games based on the 'order' property (ascending)
  const sortedGames = enabledGames.sort((a, b) => a.order - b.order);

  const [visibleGames, setVisibleGames] = useState(10);

  const loadMoreGames = () => {
    setVisibleGames(prev => Math.min(prev + 10, sortedGames.length));
  };

  // Get the visible game cards based on the state
  const visibleGameCards = sortedGames.slice(0, visibleGames);

  return (
    <div className="game-card-grid">
       <h2 className="pc-console-text1">GAMES</h2>
      <div className="game-card-grid-container">
        {/* Left part with one game (Larger size) */}
        <div className="first-column">
          {visibleGameCards.length > 0 && (
            <div className="game-card-container">
              <MainGameCard key={0} game={visibleGameCards[0]} />
            </div>
          )}
        </div>

        {/* Right part with one column for all 6 games */}
        <div className="second-column">
          {/* Merged bottom-row into bottom-column */}
          <div className="bottom-column">
            {visibleGameCards.length > 1 && (
              <div className="game-card-container">
                <MainGameCard key={1} game={visibleGameCards[1]} />
              </div>
            )}
            {visibleGameCards.length > 2 && (
              <div className="game-card-container">
                <MainGameCard key={2} game={visibleGameCards[2]} />
              </div>
            )}
            {visibleGameCards.length > 3 && (
              <div className="game-card-container">
                <MainGameCard key={3} game={visibleGameCards[3]} />
              </div>
            )}
            {visibleGameCards.length > 4 && (
              <div className="game-card-container">
                <MainGameCard key={4} game={visibleGameCards[4]} />
              </div>
            )}
             {visibleGameCards.length > 5 && (
              <div className="game-card-container">
                <MainGameCard key={4} game={visibleGameCards[5]} />
              </div>
            )}
             {visibleGameCards.length > 6 && (
              <div className="game-card-container">
                <MainGameCard key={4} game={visibleGameCards[6]} />
              </div>
            )}
            
          </div>
        </div>
      </div>

      {visibleGames < sortedGames.length && (
        <button className="load-more-btn" onClick={loadMoreGames}>
          Load More
        </button>
      )}
    </div>
  );
};

export default GamesCardGrid;