import React from 'react';
import GameCardGrid from './GameCardGrid';
import fetchJsonData from './JsonData';
import { useEffect, useState } from 'react';

const MainContent = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchJsonData();
      setData(fetchedData);
    };
    loadData();
  }, []);

  return (
    <div className='main-body'> 
      {data && (
        <>
          <main className="main" style={{ fontFamily: data.mainFont , backgroundColor: data.secondMainBackgroundColor }}> {}
            <div className='main-display-info-container'>
              <div className="main-display">
                <span className="welcome" style={{ color: data.textWelcomeColor}}>
                  {data.mainImageTextWelcome}
                </span>
                <div className="help" style={{ color: data.textHelp }}>
                  {data.mainImageTextHelp}
                </div>
              </div>
            </div>

            <div className="main-display-content-updated" style={{backgroundColor: data.secondMainBackgroundColor}}>
              <div className='main-image-container' style={{ backgroundImage: `url(${data.mainImage})` }}>
              </div>
              
            </div>

            <div className='second-main-content-updated' style={{backgroundColor: data.secondMainBackgroundColor}}>
              <div className="game-selection-content-updated">
                <div className="pc-and-console-updated">
                  <GameCardGrid games={data.Games} />
                </div>
              </div>
              <div className='third-main'>
                <div className="get-in-touch">
                  <div className="container-in-touch">
                    <h2 className="get-in-touch-sentence">{data.mainFooterTextOne}</h2>
                    <p className="description-in-touch">
                      {data.mainFooterTextTwo}
                    </p>
                    <a href="/submit-request" className="submit-request-main">SUBMIT A REQUEST</a>
                  </div>
                  <div className='get-in-touch-logo-container'>
                    <img className='get-in-touch-logo' src={data.mainFooterLogo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </div>
  );
};

export default MainContent;