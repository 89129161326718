import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { useEffect, useState } from 'react';

import Header from './components/Header';
import MainContent from './components/Main';
import Footer from './components/Footer';
import '../src/styles/MobileMenu.css' 

import fetchJsonData from './components/JsonData';


function App() {

  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchJsonData()
      setData(fetchedData)
    };
    loadData()
  }, [])

  // for Matt: font injection part start here
  useEffect(() => {
    if (!data || !data.mainFont) {
      return
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';                                // using ecoudeURI to make unproperly value, properly
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(data.mainFont)}&display=swap`;

    document.head.appendChild(link);
    
    
    return () => {
      document.head.removeChild(link);
    };
  }, [data])

  if (!data) {
    return
  }

  return (
    <div className="App" style={{fontFamily: data.mainFont}}>
      
      <Header />
      <Router>
        <Routes>
          <Route path='/'  element={<MainContent/>} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;


