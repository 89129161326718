import React, { useState } from 'react';
import '../styles/MainGameCard.css';

const MainGameCard = ({ game }) => {
  const hasMultipleUrls = game.targetUrls.length > 1;

  //selected options
  const [selectedOption] = useState(
    hasMultipleUrls ? game.targetUrls[0].label : null
  );

  const selectedTargetUrl = hasMultipleUrls
    ? game.targetUrls.find(option => option.label === selectedOption).targetUrl
    : game.targetUrls[0].targetUrl;

  return (
    <div
      className="main-game-card"
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        transition: 'transform 0.3s ease', 
        borderRadius: '5%', 
      }}
    >
      <a href={selectedTargetUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={game.logoUrl}
          alt={game.hoverTitle}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', 
            objectPosition: 'center', 
            display: 'block', 
          }}
        />
      </a>
      <div className="game-title">{game.title}</div>
    </div>
  );
};


export default MainGameCard;