import React, { useState } from 'react';
import { useEffect } from 'react';
import fetchJsonData from './JsonData';
// import MobileMenus from './MobileMenus';

const Header = () => {
  

  // variable to store data and function to take data
  const [data, setData] = useState(null);

 
 // First useEffect method use to get data and store
 
  useEffect(() => {
    // getting data and saving it in variable data
    const loadData = async () => {
      const fetchedData = await fetchJsonData();
      setData(fetchedData);

     
    };


    loadData();
  }, []); 
  return (
    <div className='main-body'>
      {data && (
  <>
    <header className="header" style={{ backgroundColor: data.headerBackgroundColor }}>
      <div className="header-container">
        <div className="header-logo-container">
          <a href={data.customerPageUrl} rel="noopener noreferrer">
            <img className="header-logo" src={data.headerLogo} alt="Logo" />
          </a>
        </div>
        <div className="header-sections">
        </div>
      </div>
    </header>
  </>
)}
    </div>
  );
};

export default Header;